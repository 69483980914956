import { sendGTMEvent } from '@next/third-parties/google'

import { type MetadataContent as AdServerMetadataContent } from '@redteclab/api/clients/adserver-api'

import { isServerSide } from '../request-context'

import { type GtmEventData, type GtmEventNames } from './model/gtmTypes'

export const gtmDataLayerPush = (args: GtmEventData): void => {
  if (isServerSide()) {
    return
  }

  sendGTMEvent(args)
}

export const gtmDataLayerPushEventUserDataAvailable = ({
  expa,
}: {
  expa?: string
}): void => {
  gtmDataLayerPush({
    event: 'userdata_available',
    'request.expa': expa,
    udinit: 1,
  })
}

const concatAdServerIds = (
  metadataContent: AdServerMetadataContent,
): string => {
  const { campaignId, creativeId, flightId } = metadataContent

  return `${campaignId}:${flightId}:${creativeId}`
}

export const gtmDataLayerPushAdServerEvent = (
  eventType: string,
  metadataContent: AdServerMetadataContent,
  eventCallback?: () => void,
): void => {
  const ids: string = concatAdServerIds(metadataContent)
  const event: Record<string, unknown> = {
    event: eventType,
    [eventType]: ids,
  }

  if (eventCallback) {
    event.eventCallback = eventCallback
  }

  window.dataLayer?.push(event)
}

const ONE_SECOND_IN_MILLIS = 1000

const gtmDataLayerFindEvent = (
  eventName: GtmEventNames,
  extraCondition?: (event: GtmEventData, index?: number) => boolean,
): GtmEventData | undefined => {
  // cast GtmEventData because of conflict between global.d.ts and @next/third-parties/google dataLayer definitions
  return (window.dataLayer as GtmEventData[] | undefined)?.find(
    (event, index) =>
      // if extra conditions is provided it must also resolve to true
      event.event === eventName && (extraCondition?.(event, index) ?? true),
  )
}

/**
 * Checks if `dataLayer` loaded event is present on data layer, by checking it
 * with interval is canceled after certain amount of retries is executed.
 */
export const gtmDataLayerWaitTillEventLoaded = async ({
  eventName,
  extraAndCondition,
  timeout,
}: {
  eventName: GtmEventNames
  extraAndCondition?: (event: GtmEventData, index?: number) => boolean
  timeout?: number
}): Promise<GtmEventData | undefined> => {
  const timeoutMs = timeout ?? ONE_SECOND_IN_MILLIS
  const timeoutInterval = 100
  const maxTimeouts = timeoutMs / timeoutInterval

  let timeoutCounter = 0

  return new Promise((resolve) => {
    const interval = setInterval(() => {
      const foundEvent = gtmDataLayerFindEvent(eventName, extraAndCondition)
      if (foundEvent) {
        clearInterval(interval)
        resolve(foundEvent)

        return
      }

      timeoutCounter += 1

      if (timeoutCounter >= maxTimeouts) {
        clearInterval(interval)
        resolve(undefined)
      }
    }, timeoutInterval)
  })
}

export const gtmDataLayerPushHomeOneAddToCart = (): void => {
  gtmDataLayerPush({
    event: 'home_one_add_to_cart',
  })
}

export const gtmDataLayerPushAccountOverviewLastOrderDetail = (): void => {
  gtmDataLayerPush({
    event: 'account_overview_last_order_detail',
  })
}

export const gtmDataLayerPushCswProduct = ({
  cswProductId,
  // used for CSW from PDP page
  productId = null,
  widgetId,
  widgetTitle = '',
}: {
  cswProductId: string | number
  productId?: string | number | null
  widgetId: number | null | string
  widgetTitle?: string
}): void => {
  gtmDataLayerPush({
    emcs0: widgetId,
    emcs1: widgetTitle,
    emcs2: productId,
    emcs3: cswProductId,
    event: 'crosssellWidgetData',
  })
}

export const gtmDataLayerPushLeafletClick = ({
  productName,
  pzn,
}: {
  productName: string
  pzn: string
}): void => {
  gtmDataLayerPush({
    event: 'leafletClick',
    'prduct.name': productName,
    'product.pzn': pzn,
  })
}

export const gtmDataLayerPushProductOffersDrawerView = ({
  offerId,
  productName,
  pzn,
}: {
  offerId: string
  productName: string
  pzn: string
}): void => {
  gtmDataLayerPush({
    event: 'product_offers_view',
    'product.name': productName,
    'product.offerId': offerId,
    'product.pzn': pzn,
  })
}

export const gtmDataLayerPushInteractionCheck = ({
  products,
}: {
  products: {
    id: string
    // in minor units (e.g. cents)
    price: number
  }[]
}): void => {
  const sumFractionDigits = 2
  gtmDataLayerPush({
    event: 'interactionCheck',
    products: products.map((product) => product.id),
    sum: (
      products.reduce((sum, product) => sum + product.price, 0) / 100
    ).toFixed(sumFractionDigits),
  })
}
