'use client'

import { GoogleTagManager } from '@next/third-parties/google'
import { type FC } from 'react'

import { useWebviewContext } from '../../webview'
import { type GtmConfig } from '../config/gtmPerTenantConfig'
import { type GtmDataProps } from '../model/GtmDataProps'
import { usePushGtmDataPropsToGtmDataLayer } from '../usePushGtmDataPropsToGtmDataLayer'

type GtmProviderProps = GtmConfig & {
  gtmData?: GtmDataProps
}

export const GtmProvider: FC<GtmProviderProps> = ({ gtmData, gtmId }) => {
  usePushGtmDataPropsToGtmDataLayer(gtmData)

  const isWebview = useWebviewContext()

  if (isWebview) {
    return null
  }

  return <GoogleTagManager gtmId={gtmId} />
}
