'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'

const WebviewContext = createContext(false)

export const WebviewContextProvider: FC<
  PropsWithChildren<{ value: boolean }>
> = (props) => <WebviewContext.Provider {...props} />

export const useWebviewContext = (): boolean => useContext(WebviewContext)
