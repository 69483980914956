'use client'

import { clsx } from 'clsx'
import { type ComponentProps, type FC, useEffect, useRef } from 'react'

import { useAdServerCloseBannerControls } from '../../useAdServerCloseBannerControls'
import { useAdServerConfigurationContext } from '../AdServerConfigurationContextProvider'

import { AdServerBannerCloseButton } from './AdServerBannerCloseButton'
import { AdServerBannerLegalText } from './AdServerBannerLegalText'

type AdSlotDimensions = {
  height: number
  width: number
}

export type AdBannerProps = ComponentProps<'div'> & {
  bannerClasses?: string
  bannerTags: string[]
  dimensions: AdSlotDimensions
  optional?: boolean

  /**
   * Reserves the space for the banner by displaying a skeleton with banner sizes
   */
  showSkeleton?: boolean
}

export const AdServerBannerContent: FC<AdBannerProps> = ({
  bannerClasses: bannerClassesProp,
  bannerTags,
  className,
  dimensions,
  showSkeleton = false,
  ...restProps
}) => {
  const { getBanner, initBanners } = useAdServerConfigurationContext()
  const bannerWrapperRef = useRef<HTMLDivElement>(null)

  const bannerClasses = clsx('as-placeholder', bannerClassesProp)

  const bannerData = getBanner({
    height: dimensions.height,
    id: bannerTags.toString(),
    width: dimensions.width,
  })

  useEffect(() => {
    // if banner data is not present on the context then we need to fetch it
    if (!bannerData) {
      initBanners([
        {
          height: dimensions.height,
          id: bannerTags.toString(),
          width: dimensions.width,
        },
      ])
    }
  }, [bannerData, bannerTags, dimensions.height, dimensions.width, initBanners])

  useEffect(() => {
    if (!bannerData) {
      return
    }

    if (bannerData.bannerElement && (showSkeleton || bannerData.metadata)) {
      // we replace the adBanner placeholder with the actual banner and add banner specific classes
      bannerData.bannerElement.className = bannerClasses
      bannerWrapperRef.current?.replaceWith(bannerData.bannerElement)
    }
  }, [bannerClasses, bannerData, showSkeleton])

  const closeBannerControls = useAdServerCloseBannerControls({
    metaDataContent: bannerData?.metadata,
  })

  if (!closeBannerControls.showBanner) {
    return null
  }

  const legalButton = bannerData?.metadata?.customData?.legalButton

  return (
    <div className={clsx('ad-server-banner', className)} {...restProps}>
      <div
        className={bannerClasses}
        ref={bannerWrapperRef}
        style={
          showSkeleton
            ? {
                aspectRatio: dimensions.width / dimensions.height,
                width: `${dimensions.width}px`,
              }
            : undefined
        }
      />
      {closeBannerControls.showCloseButton ? (
        <AdServerBannerCloseButton onClick={closeBannerControls.handleClose} />
      ) : null}
      {legalButton ? (
        <AdServerBannerLegalText
          label={legalButton.label}
          text={legalButton.text}
        />
      ) : null}
    </div>
  )
}
