import { getCookie } from 'cookies-next/client'
import { usePathname, useSearchParams } from 'next/navigation'
import { type FC, useEffect, useRef } from 'react'

import { COOKIE_AD_SERVER_USER_ID_NAME } from '../../../cookies'
import { adServerGetCampaignCode } from '../../adServerGetCampaignCode'

import { useAdServerConfigurationContext } from './useAdServerConfigurationContext'

export const AdServerSlotsCleanup: FC = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const initialPathname = useRef<string | null>(pathname)

  const { adServerTargeting } = useAdServerConfigurationContext()
  const searchParamsCampaignCode = searchParams.get('campaigncode') ?? undefined

  const { context, keywords } = adServerTargeting

  useEffect(() => {
    const campaignCodes = adServerGetCampaignCode(searchParamsCampaignCode)
    const loggedIn = Boolean(getCookie(COOKIE_AD_SERVER_USER_ID_NAME))

    window._targeting = {
      context: {
        ...context,
        campaignCodes,
        loggedIn,
      },
      keywords,
    }
  }, [context, keywords, searchParamsCampaignCode])

  useEffect(() => {
    if (pathname !== initialPathname.current) {
      window._slots?.splice(0, window._slots.length)

      initialPathname.current = null
    }
  }, [pathname])

  return null
}
