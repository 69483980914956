import { type FC, useState } from 'react'

type AdServerBannerLegalTextProps = {
  label: string
  text: string
}

export const AdServerBannerLegalText: FC<AdServerBannerLegalTextProps> = ({
  label,
  text,
}) => {
  const [visible, setVisible] = useState(false)

  return (
    <div className="absolute bottom-0 left-0 z-10 flex max-h-[75%] flex-col place-items-start text-[10px]">
      <button
        className="bg-light-primary-high px-1"
        onClick={(): void => {
          setVisible(!visible)
        }}
        type="button"
      >
        {label}
      </button>
      <p
        className="overflow-y-auto bg-light-primary-high px-1"
        // eslint-disable-next-line react/no-danger -- data are sanitized within retail media api - https://github.com/RedTecLab/retail-media-adserver-api/blob/main/src/services/kevel/KevelDecisionService.ts#L876
        dangerouslySetInnerHTML={{
          __html: text,
        }}
        hidden={!visible}
      />
    </div>
  )
}
